import request from '@/utils/request'

// 消息中心
export function message(data) {
  return request({
    url: '/api/sale/clientNotice/page',
    method: 'post',
    data
  })
}
//修改客户消息已读状态
export function goRead(data) {
  return request({
    url: '/api/sale/clientNotice/readFlag',
    method: 'post',
    data
  })
}

//一键已读
export function oneClickRead(data) {
  return request({
    url: '/api/sale/clientNotice/oneClickRead',
    method: 'post',
    data
  })
}





