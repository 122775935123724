<template>
  <div class="bg">
    <div class="msg_header_title">
      <div>
        当前位置:
        <router-link to="/" class="mycolor">众惠首页</router-link>
        ><span class="myactive">消息中心</span>
      </div>
    </div>
    <div class="msgContainer">
      <div class="msgInner">
        <div>
          <router-link to="/" class="iconfont arrleft">&#xe665;</router-link>
          <router-link to="/" class="headtext">首页</router-link>
        </div>
        <div class="home">
          <div class="msg_type">
            <div
              class="mycheck"
              @click="change(0)"
              :class="selection == 0 ? 'mychoose' : ''"
            >
              全部消息
            </div>
            <!-- <div class="mycheck">
              <input type="button" @click="change(1)" value="系统公告">
            </div> -->
            <div
              class="mycheck"
              @click="change(2)"
              :class="selection == 2 ? 'mychoose' : ''"
            >
              平台推送
            </div>
            <div
              class="mycheck"
              @click="change(3)"
              :class="selection == 3 ? 'mychoose' : ''"
            >
              交易提醒
            </div>
          </div>
          <div class="readed" @click="goRead()">一键已读</div>
        </div>
        <div class="tab">
          <div v-if="!list || list.length <= 0" class="nomessage">
            暂无任何消息哦！
          </div>
          <div
            class="probContent_detail"
            v-for="(item, index) in list"
            :key="index"
          >
            <div>
              <div class="flex-between">
                <span>{{ item.noticeTitle }}</span>
                <span>{{ item.displayTime }}</span>
              </div>
              <div v-html="item.noticeContent"></div>
            </div>
            <span :class="['btn ico-right',item.checked ? 'orange': 'gray']" @click="setRead(item,true)"></span>
          </div>
        </div>
      </div>
      <div class="mypage">
        <pagination
          v-if="total > 0"
          :total="total"
          :page.sync="pageNum"
          :limit.sync="pageSize"
          :layout="layout"
          @pagination="message"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { message, goRead,oneClickRead } from "../../../api/message";
import Vue from "vue";
import { mapActions } from "vuex";

export default {
  name: "Message",
  data() {
    return {
      total: 0,
      selection: 0,
      list: [],
      layout: "prev, pager, next,sizes",
      pageNum:1,
      pageSize:10,
    };
  },
  components: {},
  mounted() {
    this.message();
  },
  methods: {
    ...mapActions(["Unread"]),
    //切换消息类型
    change(index) {
      this.pageNum = 1;
      this.selection = index;
      this.message();
    },
    //查询消息列表
    message() {
      message({
        //通知消息类型(0全部消息 1系统公告、2平台消息、3交易提醒)
        noticeType: this.selection||null,
        //用户类型(1:销售经理 2:投资客户)
        clientType: "2",
        pageNum:this.pageNum,
        pageSize:this.pageSize
      }).then((res) => {
        this.total = res.data.total;
        //遍历返回结果，增加‘checked’字段
        let data = res.data.data;
        data.forEach((item) => {
          item.checked = item.readFlag === '0'
          item.displayTime = item.noticeType == '2' ? item.appCustDisplayTime : item.createTime
        });
        this.list = data;
      });
    },
    //一键已读
    goRead() {
      oneClickRead({noticeType:`${this.selection}`}).then(r=>{
        if(r.code==200){
          this.message();
          this.Unread()
        }
      })
    },    
    setRead(item,one) {
      if(item.checked) {
        return
      }
      goRead({ id:item.id }).then(r=>{
        if(r.code==200){
          item.checked = true;
          this.Unread()
        }
      });      
    }
  },
  destroyed(){
    this.Unread()
  }
};
</script>

<style lang="less" scoped>
  .gray{ color:#ccc}
  .orange{ color:#d89000}
.bg {
  background-color: #f3f4f6;
  .msg_header_title {
    margin: 0 auto;
    width: 1200px;
    height: 64px;
    color: #a5a5a5;
    font-size: 14px;
    line-height: 64px;
  }
  .mycolor {
    color: #a5a5a5;
  }
  .myactive {
    color: #6b6b6b;
    font-weight: 400;
  }
  .msgContainer {
    margin: 0 auto;
    width: 1200px;
    background-color: #fff;
    .msgInner {
      padding: 41px 40px 0 40px;
      .home {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .msg_type {
          display: flex;
          margin: 20px 0 50px 0;
          .mycheck {
            width: 72px;
            height: 70px;
            color: #1f1f1f;
            font-weight: 400;
            font-size: 18px;
            text-align: center;
            line-height: 70px;
            margin-right: 24px;
            border-bottom: 4px solid #fff;
            cursor: pointer;
          }
          .mychoose {
            border-bottom: 4px solid #ce9b63;
          }
        }
        .readed {
          width: 76px;
          height: 32px;
          background: linear-gradient(112deg, #f7d9b7 0%, #eabf96 100%);
          border-radius: 2px;
          text-align: center;
          line-height: 32px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
      }
      .tab {
        .nomessage {
          text-align: center;
          line-height: 60px;
          color: #eabf96;
        }
        .probContent_detail {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 20px;
          &>div:first-child{
            width:1040px;
          }
        }
      }
    }
    .headtext {
      color: #1f1f1f;
      font-size: 20px;
      margin-left: 14px;
      font-weight: 500;
    }
    .mypage {
      width: 1136px;
      height: 96px;
      margin: 0 auto;
    }
  }
}
</style>
